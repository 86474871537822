@import '@angular/flex-layout/mq';

.prep-page {
  padding: $padding;
  display: block;

  @include layout-bp(lt-md) {
    padding: $padding-md;
  }
}
