.prep-page-layout {

}

.prep-page-layout-header {
  background: $theme-color-primary;
  color: $theme-color-primary-contrast;

  prep-breadcrumbs {
    .crumb, .link {
      color: $light-secondary-text;

      &:hover {
        color: $light-primary-text;
      }
    }

    .crumb.current, .crumb.current .link {
      color: $light-primary-text;
    }
  }
}
