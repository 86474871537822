/* You can add global styles to this file, and also import other style files */
@import "./@prep/styles/core";
@import "./app/app.theme";



.mat-tooltip.table_tooltip_job_filename {
  background-color: #1976d2;
  font-size: 14px;
  width: 100% !important;
  max-width: 760px !important;
  text-align: center;
}

.mat-tooltip.table_tooltip_report_widget {
  background-color: #101223;
  font-size: 12px;
  width: 100% !important;
  max-width: 160px !important;
  text-align: left;
}

.simplebar-content-wrapper {
  overflow: hidden !important;
}


.k-window-titlebar {
  background-color: #1976d2;
  color: #FFFFFF;
}

.k-window-content {
  overflow: hidden !important;
}

// div.mat-menu-panel.fury-walkthrough-popup {
//   min-width: 400px !important;

//   .title .header {
//     color: #000000 !important;
//   }

//   .title a.close {
//     background-size: 24px !important;
//     position: relative !important;
//     top: 4px !important;
//     display: block;
//     height: 24px !important;
//     margin-right: -4px;
//     width: 24px !important;
//   }

// }

div.mat-menu-panel.bdc-walk-popup .title .header {
  color: #000000 !important;
}

div.mat-menu-panel.bdc-walk-popup .title a.close {
  background-size: 24px;
  position: relative;
  top: 4px;
  display: block;
  height: 24px;
  margin-right: -4px;
  width: 24px;
}


.advanced-pie-legend .legend-items-container .legend-items {
  overflow: unset !important;
}

.advanced-pie-legend .total-value {
  position: relative;
  font-size: 36px;
  top: -10px;
}

.k-tooltip {
  background-color: rgb(253, 255, 202) !important;
  color: #000000;
}

.k-tooltip-content {
  max-width: 300px;
  padding: 5px !important;
  color: #000000;
}

.prepk-tooltip {
  padding: 0px;
}


